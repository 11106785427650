import { Box, Flex, Heading, Text } from '@theme-ui/components';
import SEO from 'components/atoms/Seo';
import Layout from 'components/organisms/Layout';
import * as React from 'react';
import { AttentionSeeker, Fade } from 'react-awesome-reveal';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Flex
      sx={{
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        py: [5, 5, 5, 5],
        px: 3,
      }}
    >
      <AttentionSeeker effect="flash" cascade>
        <Box
          sx={{
            svg: {
              width: '100%',
              maxWidth: [200, 200, 320, 320],
              transform: 'translateX(-20px)',
            },
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 59">
            <g fill="#FFF">
              <path
                d="M52.397 54.043a.55.55 0 01-.55-.4l-.22-.726c-1.098-3.89-3.297-7.343-6.301-10.069-1.21-1.09-2.639-1.962-4.214-2.653l-2.125-.872c-.293-.11-.44-.437-.293-.728.11-.29.44-.435.733-.29l2.125.872a15.499 15.499 0 014.506 2.836c3.151 2.87 5.46 6.506 6.632 10.576l.22.727c.074.29-.073.617-.402.69a.363.363 0 01-.11.037zM10.112 8.61c-.036 0-.11 0-.146-.036L1.5 6.32a.58.58 0 01-.402-.69c.073-.292.402-.473.696-.4l8.464 2.253c.293.073.476.4.403.69a.583.583 0 01-.55.437z"
                transform="translate(0 .227)"
              />
              <path
                d="M3.443 12.39c-.036 0-.11 0-.146-.037-.294-.072-.477-.399-.403-.69l2.308-8.505c.073-.291.403-.472.696-.4.294.073.477.4.403.69l-2.308 8.506a.543.543 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M15.572 14.535c-.037 0-.11 0-.147-.037L2.967 11.191c-.294-.073-.477-.4-.403-.691.073-.291.403-.472.696-.4l12.458 3.308c.294.072.477.399.403.69a.573.573 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M5.825 20.204c-.036 0-.11 0-.147-.036-.293-.072-.476-.4-.403-.69l3.371-12.54c.073-.291.403-.472.696-.4.294.073.477.4.403.69l-3.37 12.54a.582.582 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M20.885 20.35c-.037 0-.11 0-.147-.036L4.396 15.989a.58.58 0 01-.403-.691c.073-.291.403-.472.696-.4l16.343 4.326c.293.072.476.399.403.69a.584.584 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M8.207 28.055c-.037 0-.11 0-.146-.036-.294-.072-.477-.4-.404-.69l4.47-16.647c.074-.291.404-.473.697-.4.293.073.476.4.403.691l-4.47 16.646a.582.582 0 01-.55.437z"
                transform="translate(0 .227)"
              />
              <path
                d="M26.16 26.093c-.036 0-.11 0-.146-.037l-20.19-5.343c-.293-.072-.476-.399-.402-.69.073-.291.402-.473.696-.4l20.19 5.343c.293.073.476.4.403.69a.544.544 0 01-.55.437z"
                transform="translate(0 .227)"
              />
              <path
                d="M10.625 36.088c-.036 0-.11 0-.146-.036a.58.58 0 01-.403-.69l5.606-20.791c.073-.291.403-.472.696-.4.294.072.477.4.404.69l-5.607 20.79c-.037.291-.293.437-.55.437z"
                transform="translate(0 .227)"
              />
              <path
                d="M31.512 31.908c-.037 0-.11 0-.147-.036L7.291 25.475a.58.58 0 01-.403-.69c.074-.291.403-.473.696-.4l24.074 6.397c.293.073.476.4.403.69a.544.544 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M12.971 43.794c-.037 0-.11 0-.147-.036-.293-.074-.476-.4-.402-.692L19.09 18.28c.074-.291.404-.473.697-.4a.58.58 0 01.402.69L13.52 43.358a.582.582 0 01-.549.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M34.15 37.105c-.037 0-.11 0-.147-.035L8.757 30.381a.58.58 0 01-.403-.69c.074-.291.403-.473.696-.4l25.247 6.687c.293.073.476.4.403.69a.545.545 0 01-.55.437z"
                transform="translate(0 .227)"
              />
              <path
                d="M16.708 47.101c-.037 0-.11 0-.147-.037-.293-.073-.476-.399-.403-.69l6.522-24.17c.073-.292.403-.473.696-.4.293.072.476.4.403.69l-6.521 24.171a.582.582 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M30.925 40.594c-.037 0-.11 0-.147-.036l-20.593-5.452c-.292-.072-.476-.399-.402-.69.073-.29.402-.472.696-.4l20.592 5.452c.294.073.477.4.404.69a.583.583 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M21.508 45.974c-.037 0-.11 0-.146-.036-.294-.073-.477-.4-.403-.691l5.203-19.3c.073-.29.403-.472.696-.4.293.073.476.4.403.691l-5.203 19.3a.583.583 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M26.126 43.83c-.037 0-.11 0-.147-.036l-14.29-3.78c-.294-.074-.477-.4-.403-.692.073-.29.403-.472.696-.4l14.29 3.781c.294.073.477.4.404.69a.584.584 0 01-.55.437z"
                transform="translate(0 .227)"
              />
              <path
                d="M26.638 43.503c-.037 0-.11 0-.147-.037-.294-.073-.477-.4-.403-.69l3.517-13.049c.074-.29.404-.472.697-.399a.58.58 0 01.402.69l-3.518 13.049a.542.542 0 01-.548.436zM19.603 46.556c-.037 0-.11 0-.147-.036l-6.302-1.673c-.293-.072-.476-.4-.403-.69.073-.291.403-.472.696-.4l6.303 1.672a.58.58 0 01.402.69c-.036.291-.292.436-.549.436zM32.17 39.468c-.036 0-.109 0-.146-.036-.293-.073-.476-.4-.403-.69l1.429-5.307c.074-.29.403-.472.696-.4.293.073.476.4.403.691l-1.429 5.307a.544.544 0 01-.55.436z"
                transform="translate(0 .227)"
              />
              <path
                d="M60.019 57.896H10.882c-.916 0-1.686-.618-1.906-1.527-.22-.908.22-1.781 1.063-2.18l3.444-1.6a14.357 14.357 0 014.324-1.271c3.225-.4 7.035-1.745 10.7-3.781l.11-.073a.635.635 0 01.623 0c.183.109.33.255.33.473v2.399c0 .544.403 1.017.88 1.09.292.036.548-.073.769-.255a.965.965 0 00.33-.727v-.363c0-1.127.842-2.109 1.905-2.218a2.079 2.079 0 011.649.546c.44.403.692.969.696 1.563v2.108c0 .545.403 1.017.88 1.09.293.036.549-.073.768-.254a.965.965 0 00.33-.727V41.975c0-1.236.294-2.434.88-3.452.55-.982.293-2.181-.55-2.836-.513-.399-1.172-.544-1.832-.399a2.185 2.185 0 00-1.466 1.127c-1.209 2.29-4.543 5.197-8.5 7.378-3.921 2.18-8.172 3.49-10.774 3.307a3.063 3.063 0 01-2.748-2.144L.365 3.594c-.33-1.127.11-2.29 1.173-2.871 1.026-.582 2.272-.4 3.078.472l30.595 33.33c.294-.145.587-.291.916-.327a3.25 3.25 0 012.749.617c1.282.982 1.648 2.8.842 4.217-.506.9-.77 1.914-.769 2.944V52.19a2.142 2.142 0 01-.696 1.563c-.44.4-1.063.581-1.649.544-1.062-.108-1.905-1.09-1.905-2.216v-2.109c0-.29-.11-.545-.33-.727-.22-.181-.476-.29-.77-.254-.513.036-.88.509-.88 1.09v.364a2.142 2.142 0 01-.695 1.563c-.44.4-1.063.581-1.649.545-1.063-.109-1.905-1.09-1.905-2.217v-1.454c-3.628 1.927-7.292 3.162-10.48 3.562a12.268 12.268 0 00-3.994 1.163l-3.444 1.599c-.44.218-.514.581-.44.908.074.292.293.619.77.619h49.136c.44 0 .66-.291.696-.546.073-.255 0-.617-.403-.8l-9.234-4.434a.557.557 0 01-.256-.763.558.558 0 01.769-.255l9.234 4.434c.806.4 1.209 1.236.989 2.072-.182.873-.878 1.455-1.794 1.455zM2.784 1.523c-.257 0-.513.072-.696.181-.44.254-.88.8-.623 1.563l12.531 41.362c.22.763.916 1.272 1.686 1.345 2.418.181 6.412-1.091 10.15-3.162 3.774-2.072 6.925-4.798 8.025-6.906.11-.219.256-.437.44-.618L3.772 1.995c-.293-.327-.659-.473-.989-.473z"
                transform="translate(0 .227)"
              />
            </g>
          </svg>
        </Box>
      </AttentionSeeker>
      <Fade cascade delay={1000}>
        <Heading as="h1" sx={{ mb: 2 }}>
          Whoops!
        </Heading>
        <Text>Page not found</Text>
      </Fade>
    </Flex>
  </Layout>
);

export default NotFoundPage;
